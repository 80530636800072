import React,{createRef} from "react";
import { XCircle, Send } from 'react-feather'
import ScrollableFeed from "react-scrollable-feed";


const ref = React.createRef()

class SendSms extends React.Component {
    chatContainer = React.createRef();
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef()
       
    }
  
      componentDidMount () {
        // this.scrollToBottom()
        // this.timer=setTimeout(()=>{
        // this.scrollToBottom()
        // },1000)
      }
      // shouldComponentUpdate(nextProps, nextState) {
        // if(this.state.dropdownVal != nextState.dropdownval) {
        //      return false
        // }
        // return true
    // }   
      // componentDidUpdate (prevProps) {
      //     console.log(prevProps, this.props)
          // this.scrollToBottom()
          // if(prevProps !== this.props){
        // this.scrollToBottom()
      // }
        // clearTimeout(this.timer)
      // }
      componentWillReceiveProps(prevProps, newProps){
        // console.log(prevProps, newProps)
        
        if(prevProps !== newProps){
            // this.scrollToBottom()
        }
      }
      // componentWillUnmount(){
      //   clearTimeout(this.timer)
      // }
      // componentWillReceiveProps(prevProps, newProps){
      //       this.scrollToBottom()
      // }
      scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
      }
    render() {
        let newMsg = this.props?.newMsg
        let link = this.props?.link
        return (
            <>
                {/* <ScrollableFeed>  */}
                
                  {newMsg !== "" && newMsg !== null ?
                    <div ref={this.messagesEndRef} className="message-send text-right" >
                       {link?
                      <a className="message_s_p" target="_blank" href={newMsg.split('.')[0].trim()=='www'?'https://'+newMsg:newMsg}>{newMsg}</a> :
                        <p className="message_s_p">
                            {newMsg}
                        </p>
                         } 
                    </div> : ''}
                   
                    {/* </ScrollableFeed> */}
            </>
        )
    }
}

export default SendSms;
